import { styled, Grid, GridProps } from '@mui/material';

export const StyledGrid = styled(Grid)<GridProps>`
  min-height: 100%;

  .MuiGrid-root {
    align-self: center;
    text-align: center;
  }

  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
