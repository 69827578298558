import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { Maintenance as MaintenanceIcon } from 'shared/components/Icons';

import { formatDate } from './Maintenance.utils';
import { StyledGrid } from './Grid.styled';

interface MaintenanceProps {
  startDate?: string;
  endDate?: string;
}

export const Maintenance: FC<MaintenanceProps> = ({ startDate = '', endDate = '' }) => {
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <MaintenanceIcon />
        <Typography variant='h4' fontWeight={300} mt={5} mb={1}>
          Under maintenance
        </Typography>
        <Typography
          variant='body1'
          mx={1}
        >{`We are currently undergoing scheduled maintenance from ${formattedStartDate} to ${formattedEndDate}`}</Typography>
      </Grid>
    </StyledGrid>
  );
};
